<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 562.75 450.11"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M300.48,112.52v75H483.82c25.84,0,41.44,15.57,41.45,41.33q0,34.75.05,69.51a2.88,2.88,0,0,0,.15.75c5.45,1.66,11.1,2.63,16,5,13.66,6.62,20.89,18.05,21.05,33.11.28,26.49.31,53-.4,79.48-.45,16.66-16.13,32-32.87,32.76-14.63.66-29.34.88-44,.09-19.9-1.08-34.48-16.21-34.9-36.24q-.78-38.24,0-76.5c.38-19.65,14.07-33.74,33.8-36.51,1.29-.18,3.36-1.92,3.37-2.95.19-23.82.15-47.63.15-71.9H300.51v73.65c5.43,1.66,11.08,2.64,16.05,5.05,13.66,6.63,20.84,18.09,21,33.14.27,26.49.3,53-.41,79.48-.44,16.43-16.09,31.92-32.44,32.66-14.8.67-29.68.89-44.46.11-19.86-1.06-34.43-16.26-34.83-36.3-.51-25.49-.44-51,0-76.5a37,37,0,0,1,34.4-36.4,20.5,20.5,0,0,0,2.65-.59V225.46h-187v73.67c5.5,1.69,11.16,2.68,16.12,5.1,13.67,6.64,20.79,18.13,20.94,33.17.26,26.5.29,53-.43,79.49-.44,16.37-16.14,31.84-32.5,32.59a435.54,435.54,0,0,1-44,.13C15.31,448.49.77,433.43.38,413.21Q-.37,375,.39,336.7c.41-19.64,14.59-34.16,34-36.28,1-.11,1.91-.4,3.37-.71,0-2.21,0-4.34,0-6.47.1-23.5-.46-47,.5-70.49C39.07,202.15,55,188,75.78,187.57c11.5-.21,23,0,34.5,0H262.46v-75h-5.88c-34.84,0-69.68,0-104.51,0-19.13,0-32.8-10.37-38-28.53a23.4,23.4,0,0,1-.94-6.38c0-15.15-.49-30.34.31-45.46.82-15.52,16.46-30.35,32-31.61C149.23.2,153.07,0,156.89,0q125.52,0,251,.05a53.92,53.92,0,0,1,14.8,1.62c16.76,4.84,27,18.32,27.46,35.74q.45,18.74,0,37.5c-.53,21.91-16.8,37.49-39.12,37.56-34.67.11-69.35,0-104,0Zm112-74.68h-262V74.6h262ZM75,338H38v74.31H75Zm188.1-.16v74.53H300V337.79Zm261.8,74.52V338H488.06v74.33Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconOrganisations',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
